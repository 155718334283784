import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/auth/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { db } from '../firebase/firebase';
import { doc, getDoc, collection, getDocs, setDoc, updateDoc } from 'firebase/firestore';

function Dashboard() {
  const { currentUser, userLoggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const [referralLink, setReferralLink] = useState('');
  const [referralClicks, setReferralClicks] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state for fetching referral code
  const [monthlySales, setMonthlySales] = useState(0);  
  const [lifetimeSales, setLifetimeSales] = useState(0);  

  useEffect(() => {
    const fetchReferralCode = async () => {
      if (userLoggedIn && currentUser) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const baseUrl = window.location.origin;
            setReferralLink(`${baseUrl}/shop?ref=${userData.referralCode}`);
            setMonthlySales(userData.monthlySaleCount || 0);
            setLifetimeSales(userData.lifetimeSaleCount || 0);
            setReferralClicks(userData.referralClickCount || 0);
          } else {
            console.error('User document does not exist');
          }
        } catch (error) {
          console.error('Failed to fetch referral code', error);
        } finally {
          setLoading(false); // Stop loading after fetching
        }
      }
    };

    fetchReferralCode();
  }, [userLoggedIn, currentUser]);

  useEffect(() => {
    const checkAndResetMonthlySaleCount = async () => {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();

      const lastResetDocRef = doc(db, 'metadata', 'lastReset');
      const lastResetDoc = await getDoc(lastResetDocRef);

      if (lastResetDoc.exists()) {
        const lastResetData = lastResetDoc.data();
        console.log('existing last reset data:', lastResetData);
        const lastResetDate = new Date(lastResetData.date);
        const lastResetMonth = lastResetDate.getMonth();
        const lastResetYear = lastResetDate.getFullYear();

        if (currentMonth !== lastResetMonth || currentYear !== lastResetYear) {
          // Reset monthlySaleCount for all users
          const usersRef = collection(db, 'users');
          const snapshot = await getDocs(usersRef);

          const batch = db.batch();
          snapshot.forEach((doc) => {
            const userDocRef = usersRef.doc(doc.id);
            batch.update(userDocRef, { monthlySaleCount: 0 });
          });

          await batch.commit();
          await setDoc(lastResetDocRef, { date: currentDate.toISOString() });
          console.log('Monthly sale counts reset for all users');
        }
      } else {
        // Initialize last reset date
        await setDoc(lastResetDocRef, { date: currentDate.toISOString() });
      }
    };

    checkAndResetMonthlySaleCount();
  }, []);

  if (!userLoggedIn) {
    return <Navigate to="/login" />;
  }

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  return (
    <div className="dashboard-container" style={{marginLeft: '50px', marginRight: '50px'}}>
      <div className='row ' style={{marginTop: '40px'}}>
      <div className='col-md'>
      <h1 className='white-text slide-fade text-start'>Affiliate Dashboard</h1>
        </div>
        <div className='col-md-2 d-flex align-items-center justify-content-center'>
        <a className='click-2' onClick={handleLogout}>Logout</a>
        </div>
      </div>
      <p className='white-text slide-fade text-start'>Welcome, {currentUser?.email}! You are logged in.</p>
      
      <div className='row slide-fade border-black justify-content-start mt-2'>
        <div className='col-md-5 m-3 box' style={{background: 'black'}}>
        {loading ? (
        <p className='white-text'>Loading your sales information...</p>
      ) : (
        referralLink && (
          <div className='referral-section slide-fade' style={{background: 'black'}}>
            <h1 className='white-text slide-fade d-flex text-start'style={{background: 'black', fontSize: '100px'}} >$100.00</h1>
            <p className='white-text text-start mt-3' style={{background: 'black'}}>Lifetime Earnings</p>
            <a className='click-2 d-flex d-inline-block justify-content-start mt-2' onClick={() => navigator.clipboard.writeText(referralLink)}>
              Withdraw
            </a>
          </div>
        )
      )}
        </div>


        <div className='col-md-6 slide-fade m-3' style={{background: 'none'}}>
          <div className='row slide-fade box' style={{background: 'none'}}>

        {loading ? (
        <p className='white-text'>Loading your monthly sales...</p>
      ) : (
        referralLink && (
          <div className='row slide-fade referral-section' style={{background: 'none'}}>
            
            <div className='col-md'>
            <h3 className='white-text text-start slide-fade'>{monthlySales}</h3>
            <p className='white-text text-start' >Monthly Sales</p>
</div>
<div className='col-md' style={{borderLeft: '1px solid white', paddingLeft: '20px'}}>
            <h3 className='white-text text-start slide-fade'>{lifetimeSales}</h3>
            <p className='white-text text-start'>Lifetime Sales</p>
</div>
          </div>
        )
      )}
        </div>


        <div className='row mt-4 slide-fade  box' style={{background: 'none'}}s>

{loading ? (
<p className='white-text'>Loading your monthly sales...</p>
) : (
referralLink && (
  <div className='row referral-section slide-fade' style={{background: 'none'}}>
    
    <div className='col-md'>
    <h3 className='white-text text-start slide-fade'>Silver</h3>
    <p className='white-text text-start' >Commission Tier</p>
</div>
<div className='col-md' style={{borderLeft: '1px solid white', paddingLeft: '20px'}}>
    <h3 className='white-text text-start slide-fade'>10%</h3>
    <p className='white-text text-start'>Commission Rate</p>
</div>
  </div>
)
)}
</div>
        
        </div>


        
      
      </div>


      <div className='row slide-fade justify-content-start mt-2' style={{background: 'black'}}>
        <div className='col-md-7 box  m-3 slide-fade ' style={{background: 'black'}}>
        {loading ? (
        <p className='white-text'>Loading your referral link...</p>
      ) : (
        referralLink && (
          <div className='referral-section slide-fade' style={{background: 'black'}}>
            <h2 className='white-text text-start slide-fade'style={{background: 'black'}} >Unique Referral Link</h2>
            <p className='white-text text-start mt-3 slide-fade' style={{background: 'black'}}>{referralLink}</p>
            <a className='click-2 d-flex d-inline-block justify-content-start slide-fade mt-2' onClick={() => navigator.clipboard.writeText(referralLink)}>
              Copy Link
            </a>
          </div>
        )
      )}
    

  
        </div>


        <div className='col-md-4 slide-fade box m-3' style={{background: 'none'}}>


        <div className='row mt-4 slide-fade m-3 ' style={{background: 'none'}}s>

{loading ? (
<p className='white-text'>Loading your monthly sales...</p>
) : (
referralLink && (
  <div className='row referral-section slide-fade' style={{background: 'none'}}>
    
    <div className='col-md'>
    <h3 className='white-text text-start slide-fade'>{referralClicks}</h3>
    <p className='white-text text-start' >Referral Clicks</p>
</div>
<div className='col-md' style={{borderLeft: '1px solid white', paddingLeft: '20px'}}>
    <h3 className='white-text text-start slide-fade'>{ ((lifetimeSales / referralClicks)*100).toFixed(1) }%</h3>
    <p className='white-text text-start'>Turnaround Rate</p>
</div>
  </div>
)
)}
</div>
        
        </div>

        
        </div>
      
      <br></br>
    </div>
  );
}

export default Dashboard;
