import React, { useState } from 'react';
import bundle from '../images/adverts.jpg';
import keyboard from '../images/keyboard.jpg';
import mouse from '../images/mouse.jpg';
import headset from '../images/headset.jpg';
import { useCart } from './Cart';


const Shop = () => {
 

    const { addToCart } = useCart();

    const handleAddToCart = (product) => {
        addToCart(product);
        alert(`${product.name} added to cart!`);
        console.log(`Added ${product.name} to cart`);
      };
    return(

        <div>

                <div className='container slide-fade'>

                    <h1 className='white-text mt-4 slide-fade'>Shop</h1>
                    
                    <div>
          
        
            <div>
                <div className='row d-flex slide-fade justify-content-center box mt-4' style={{lineHeight: '30px', marginRight: '20px', marginLeft: '20px', background: 'none'}}>
                    <div className='col-md-2 text-center'>
                        <a style={{textDecoration: 'none'}} className='link nav-link-hover' href='#bundle'><span className='white-text'>Bundle</span></a>
                    </div>
                    <div className='col-md-2 text-center'>
                    <a style={{textDecoration: 'none'}} className='link nav-link-hover' href='#keyboard'><span className='white-text'>Keyboard</span></a>
                    </div>
                    <div className='col-md-2 text-center'>
                    <a style={{textDecoration: 'none'}} className='link nav-link-hover' href='#mouse'><span className='white-text'>Mouse</span></a>
                    </div>
                    <div className='col-md-2 text-center'>
                    <a style={{textDecoration: 'none'}} className='link nav-link-hover' href='#headset'><span className='white-text'>Headset</span></a>
                    </div>
                </div>
            </div>
        </div>



                    <div className='row justify-content-center d-flex align-items-center' style={{margin: '10px'}}>
                        <div className='col-md-12 mt-5 box' id='bundle' style={{background: 'none'}}>
                            <div className='row d-flex justify-content-center' style={{margin: '10px'}}>
                                <div className='col-md-5 image-container d-flex justify-content-center align-items-center'>
                                    <img src={bundle} className='zoom-image' style={{maxHeight: '290px'}}></img>
                                </div>
                                <div className='col-md  vertical-space'>
                                        <div className='row text-start'  style={{borderLeft: '1px solid white'}}>
                                            <h4 className='white-text'>Exclusive Bundle ⎯ <i className='white-text'>"Founders Collection"</i></h4>
                                            <p className='white-text mt-3'>Introducing the Kinxe Gaming Bundle – the ultimate package for gamers who demand performance, comfort, and style. This comprehensive set includes a high-precision 2.4G wireless gaming mouse, a compact 63-key mechanical RGB gaming keyboard, and a wireless 2.4G RGB gaming headset for seamless, lag-free play. As a bonus, the bundle also comes with a 9×10 inch gaming mouse pad, ensuring you're fully equipped for an immersive gaming experience.</p>
                                            <p className='white-text mt-3'>Bundle Includes:</p>
                                            <p className='white-text'>Kinxe 2.4G Wireless Gaming Mouse ⎯ <i>Original Price: $29.99</i></p>
                                            <p className='white-text'>Kinxe Compact 63-Key Mechanical RGB Gaming Keyboard ⎯ <i>Original Price: $49.99</i></p>
                                            <p className='white-text'>Kinxe 2.4G RGB Wireless Gaming Headset ⎯ <i>Original Price: $44.99</i></p>
                                            <p className='white-text'><strong>Bonus: </strong> Kinxe Mousepad</p>
                                            <a href="" onClick={() => handleAddToCart({ name: 'Exclusive Bundle', price: 69.99, image: bundle, description: "Introducing the Kinxe Gaming Bundle – the ultimate package for gamers who demand performance, comfort, and style. This comprehensive set includes a high-precision 2.4G wireless gaming mouse, a compact 63-key mechanical RGB gaming keyboard, and a wireless 2.4G RGB gaming headset for seamless, lag-free play. As a bonus, the bundle also comes with a 9×10 inch gaming mouse pad, ensuring you're fully equipped for an immersive gaming experience." })} className="click" style={{width: '200px', marginLeft:'10px'}}>
  <s style={{background: 'none'}}>$100.00</s> $69.99
  <svg style={{background: 'none', marginLeft:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bag" viewBox="0 0 16 16">
    <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
  </svg>
  <br></br>
  <span style={{background: 'none'}}> </span>
</a>
                                        </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className='col-md-12 mt-5 box' id='keyboard' style={{background: 'none'}}>
                            <div className='row d-flex justify-content-center' style={{margin: '10px'}}>
                                <div className='col-md-5 image-container d-flex justify-content-center align-items-center'>
                                    <img src={keyboard} className='zoom-image' style={{minWidth: '10px'}}></img>
                                </div>
                                <div className='col-md vertical-space'>
                                        <div className='row text-start' style={{borderLeft: '1px solid white'}}>
                                            <h4 className='white-text'>Kinxe Gaming Keyboard</h4>
                                            <p className='white-text mt-3'>The Kinxe gaming keyboard combines a minimalist 63-key layout with robust functionality, perfect for streamlined setups. Its mechanical switches provide durable, satisfying tactile feedback, while individually backlit keys with vibrant RGB lighting ensure clear visibility, even in low-light conditions. Customize your gaming experience with nine dynamic flashing modes and multiple color options to suit your mood and style. The USB wired interface guarantees a stable, lag-free connection for reliable performance and instant response times. Designed for comfort, the keyboard features an adjustable stand to achieve the ideal typing angle, all within a compact footprint of 436mm x 120mm x 36mm.</p>
                                            <p className='white-text mt-3'>Important Information:</p>
                                            <p className='white-text'>Layout ⎯ <i>63-Key Layout</i></p>
                                            <p className='white-text'>RGB  ⎯ <i>9 Dynamic Flashing Modes & Various Colour Options</i></p>
                                            <p className='white-text'>USB Wired  ⎯ <i>Yes</i></p>
                                            <p className='white-text'>Footprint ⎯ <i>436mm x 120mm x 36mm</i></p>
                                            {/* <a href="" onClick={() => handleAddToCart({ name: 'Kinxe Gaming Keyboard', price: 29.99, image: keyboard, description: 'The Kinxe gaming keyboard combines a minimalist 63-key layout with robust functionality, perfect for streamlined setups. Its mechanical switches provide durable, satisfying tactile feedback, while individually backlit keys with vibrant RGB lighting ensure clear visibility, even in low-light conditions. Customize your gaming experience with nine dynamic flashing modes and multiple color options to suit your mood and style. The USB wired interface guarantees a stable, lag-free connection for reliable performance and instant response times. Designed for comfort, the keyboard features an adjustable stand to achieve the ideal typing angle, all within a compact footprint of 436mm x 120mm x 36mm.'  })} className="click" style={{width: '200px', marginLeft:'10px'}}>
                                                    <s style={{background: 'none'}}>$49.99</s> $29.99 <svg style={{background: 'none', marginLeft:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
</svg>
                                                    <br>
                                                    </br>
                                                    <span style={{background: 'none'}}>
                                                        </span>
                                            </a> */}
                                            <a className="click" style={{width: '200px', marginLeft:'10px'}}>Coming Soon <svg style={{background: 'none', marginLeft:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
</svg>
                                                    <br>
                                                    </br>
                                                    <span style={{background: 'none'}}>
                                                        </span>
                                            </a>
                                        </div>
                                </div>
                            </div>
                        </div>


                        <div className='col-md-12 mt-5 box' id='mouse' style={{background: 'none'}}>
                            <div className='row d-flex justify-content-center' style={{margin: '10px'}}>
                                <div className='col-md-5 image-container d-flex justify-content-center align-items-center'>
                                    <img src={mouse} className='zoom-image' style={{minWidth: '10px'}}></img>
                                </div>
                                <div className='col-md vertical-space'>
                                        <div className='row text-start' style={{borderLeft: '1px solid white'}}>
                                            <h4 className='white-text'>Kinxe Gaming Mouse</h4>
                                            <p className='white-text mt-3'>The Kinxe gaming mouse is designed for comfort and precision, featuring a lightweight 90g build for easy maneuverability and reduced strain during extended use. Powered by a high-performance optical sensor with a DPI range of 1200 to 3200, it delivers accurate tracking and responsiveness. Equipped with a 500mAh rechargeable battery, this mouse ensures long-lasting performance. Enhance your setup with dynamic RGB lighting modes, customizable to match your style. With dual switch functionality, effortlessly switch between two devices, while adjustable speeds, a maximum tracking speed of 30 ips, and 10g acceleration provide ultimate control. Complete with 7 programmable keys, you can tailor the mouse to boost productivity and gaming efficiency.</p>
                                            <p className='white-text mt-3'>Important Information:</p>
                                            <p className='white-text'>Weight ⎯ <i>90g</i></p>
                                            <p className='white-text'>DPI Range ⎯ <i>1200-3200</i></p>
                                            <p className='white-text'>500mAh Rechargeable Battery</p>
                                            <p className='white-text'>Dual-Switch Functionality</p>
                                            <p className='white-text'>Maximum Tracking Speed ⎯ <i>30 Inches per Second</i></p>
                                            <p className='white-text'>(7) Programmable Keys</p>
                                            {/* <a href="" onClick={() => handleAddToCart({ name: 'Kinxe Gaming Mouse', price: 19.99, image: mouse, description: "The Kinxe gaming mouse is designed for comfort and precision, featuring a lightweight 90g build for easy maneuverability and reduced strain during extended use. Powered by a high-performance optical sensor with a DPI range of 1200 to 3200, it delivers accurate tracking and responsiveness. Equipped with a 500mAh rechargeable battery, this mouse ensures long-lasting performance. Enhance your setup with dynamic RGB lighting modes, customizable to match your style. With dual switch functionality, effortlessly switch between two devices, while adjustable speeds, a maximum tracking speed of 30 ips, and 10g acceleration provide ultimate control. Complete with 7 programmable keys, you can tailor the mouse to boost productivity and gaming efficiency."  })}className="click" style={{width: '200px', marginLeft:'10px'}}>
                                                    <s style={{background: 'none'}}>$29.99</s> $19.99 <svg style={{background: 'none', marginLeft:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
</svg>
                                                    <br>
                                                    </br>
                                                    <span style={{background: 'none'}}>
                                                        </span>
                                            </a> */}
                                            <a className="click" style={{width: '200px', marginLeft:'10px'}}>Coming Soon <svg style={{background: 'none', marginLeft:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
</svg>
                                                    <br>
                                                    </br>
                                                    <span style={{background: 'none'}}>
                                                        </span>
                                            </a>
                                        </div>
                                </div>
                            </div>
                        </div>
                    


                        <div className='col-md-12 mt-5 box' id='headset' style={{background: 'none'}}>
                            <div className='row d-flex justify-content-center' style={{margin: '10px'}}>
                                <div className='col-md-5 image-container d-flex justify-content-center align-items-center'>
                                    <img src={headset} className='zoom-image' style={{minWidth: '10px'}}></img>
                                </div>
                                <div className='col-md  vertical-space'>
                                        <div className='row text-start' style={{borderLeft: '1px solid white'}}>
                                            <h4 className='white-text'>Kinxe Gaming Headset</h4>
                                            <p className='white-text mt-3'>The Kinxe 2.4G Bluetooth Wireless Headphones deliver exceptional sound quality and convenience for gaming and everyday use. With advanced noise-canceling microphone technology, enjoy crystal-clear communication free from distractions. Designed for versatility, these headphones are waterproof and feature an LED display with no delay time, ensuring seamless performance in any environment. Safety and quality are guaranteed with CE and ROHS certifications, meeting European safety standards. Equipped with a rechargeable 600mAh battery and a Type-C charging port, these headphones provide extended use and easy recharging. Compatible with mobile phones, laptops, computers, PS5, PS4, Xbox, and PCs, the Kinxe headphones are the perfect all-in-one audio solution.</p>
                                            <p className='white-text mt-3'>Important Information:</p>
                                            <p className='white-text'>Bluetooth Wireless ⎯ <i>2.4G</i></p>
                                            <p className='white-text'>Noise Cancellation Microphone</p>
                                            <p className='white-text'>Waterproof</p>
                                            <p className='white-text'>LED Display</p>
                                            <p className='white-text'>CE & ROHS Certified</p>
                                            <p className='white-text'>Rechargeable 600mAh Battery ⎯ Type-C Charging Port </p>
                                            {/* <a href="" onClick={() => handleAddToCart({ name: 'Kinxe Gaming Headset', price: 34.99, image: headset, description: "The Kinxe 2.4G Bluetooth Wireless Headphones deliver exceptional sound quality and convenience for gaming and everyday use. With advanced noise-canceling microphone technology, enjoy crystal-clear communication free from distractions. Designed for versatility, these headphones are waterproof and feature an LED display with no delay time, ensuring seamless performance in any environment. Safety and quality are guaranteed with CE and ROHS certifications, meeting European safety standards. Equipped with a rechargeable 600mAh battery and a Type-C charging port, these headphones provide extended use and easy recharging. Compatible with mobile phones, laptops, computers, PS5, PS4, Xbox, and PCs, the Kinxe headphones are the perfect all-in-one audio solution." })} className="click" style={{width: '200px', marginLeft:'10px'}}>
                                                    <s style={{background: 'none'}}>$44.99</s> $34.99 <svg style={{background: 'none', marginLeft:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
</svg>
                                                    <br>
                                                    </br>
                                                    <span style={{background: 'none'}}>
                                                        </span>
                                            </a> */}

<a className="click" style={{width: '200px', marginLeft:'10px'}}>Coming Soon <svg style={{background: 'none', marginLeft:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
</svg>
                                                    <br>
                                                    </br>
                                                    <span style={{background: 'none'}}>
                                                        </span>
                                            </a>
                                        </div>

                                </div>
                            </div>
                        </div>
                    
                   

                    </div>
                </div>

            


        </div>



    );





}


export default Shop;