import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doPasswordReset } from '../firebase/auth';
import { Form } from 'react-bootstrap';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      await doPasswordReset(email);
      setMessage('Password reset email sent. Check your inbox.');
      setTimeout(() => navigate('/login'), 3000); // Redirect to login after 3 seconds
    } catch (err) {
      setError('Failed to reset password. ' + err.message);
      console.error("Error resetting password:", err);
    }
  };

  return (
    <div className="forgot-password-container">
      <div className='row d-flex justify-content-center'>
        <div className='row d-flex justify-content-center' style={{margin: '20px'}}>
          <div className='col-md-5 box' style={{background: 'none'}}>
            <h2 className='white-text mt-3'>Reset Your Password</h2>

            <Form className="contact-form m-4" onSubmit={handleSubmit} style={{padding: '15px'}}>
              <Form.Group className="mb-3" controlId="formBasicEmail" style={{background: 'none'}}>
                <Form.Label className='white-text d-flex text-start' style={{background: 'none'}}>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              {error && <p style={{ color: 'red' }}>{error}</p>}
              {message && <p style={{ color: 'green' }}>{message}</p>}

              <a href="#" className='d-flex click-2' style={{marginTop: '30px'}} onClick={handleSubmit}>
                Reset Password
              </a>

              <p className='mt-4'>
                Remember your password? <a href="/login">Log in</a>
              </p>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
