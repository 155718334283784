import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth/AuthContext';
import { doSignInWithEmailAndPassword, doSignInWithGoogle, checkEmailVerification, doSendEmailVerification } from '../firebase/auth';
import { Form } from 'react-bootstrap';


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { userLoggedIn } = useAuth();
  const [isVerifying, setIsVerifying] = useState(false);
  useEffect(() => {
    if (userLoggedIn) {
      navigate('/dashboard');
    }
  }, [userLoggedIn, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setError('');
    setIsVerifying(true);
  
    try {
      // Sign in with email and password
      await doSignInWithEmailAndPassword(email, password);
  
      // If successful and email is verified, the AuthContext will redirect
      navigate('/dashboard');
    } catch (err) {
      setError(err.message || "An error occurred during login. Please try again.");
    } finally {
      setIsVerifying(false);
    }
  };
  

  const handleResendVerification = async () => {
    try {
      await doSendEmailVerification();
      setError("Verification email resent. Please check your inbox.");
    } catch (err) {
      setError("Failed to resend verification email. Please try again later.");
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await doSignInWithGoogle();
    } catch (err) {
      setError(err.message || 'Failed to sign in with Google');
    }
  };
  return (
    <div className="login-container">
        <div className='row d-flex justify-content-center'>
            <div className='row d-flex justify-content-center' style={{margin: '20px'}}>
                <div className='col-md-5 box' style={{background: 'none'}}>
                    <h2 className='white-text mt-3'>Affiliate Account Login</h2>

                    <Form className="contact-form m-4" onSubmit={handleSubmit} style={{padding: '15px'}}>
                        <Form.Group className="mb-3" controlId="formBasicEmail" style={{background: 'none'}}>
                            <Form.Label className='white-text d-flex text-start' style={{background: 'none'}}>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className='white-text d-flex text-start'>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
           
{error && <div className="alert alert-danger">{error}</div>}
        
        
      
                        <button 
                          type="submit"
                          className='d-flex click-2' 
                          style={{marginTop: '30px', border: 'none'}}
                        >
                            Login
                        </button>
                        
                        <p className='white-text m-2'>or</p>
              
                        <button 
                          type="button"
                          className='click-2 d-inline-block' 
                          style={{marginTop: '20px', border: 'none'}} 
                          onClick={handleGoogleSignIn}
                        >
                          Sign in with Google
                          <svg xmlns="http://www.w3.org/2000/svg" style={{background: 'none', marginLeft: '15px'}} width="16" height="16" fill="black" className="bi bi-google" viewBox="0 0 16 16">
                              <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z"/>
                          </svg>
                        </button>

                        <p className='mt-4 white-text'>
                            Don't have an account? <a href="/signup" className='white-text'>Sign up</a>
                        </p>
                        <p>
                            <a href="/forgot-password" className='white-text'>Forgot Password?</a>
                        </p>
                    </Form>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Login;