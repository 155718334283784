import { auth, db } from "./firebase";
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  GoogleAuthProvider, 
  signInWithPopup,
  sendEmailVerification,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  updatePassword,
  signOut
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";

const generateReferralCode = () => {
  return Math.random().toString(36).substring(2, 8).toUpperCase();
};

export const doCreateUserWithEmailAndPassword = async (email, password) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    const user = result.user;
    
    // Send email verification
    await sendEmailVerification(user);

    const referralCode = generateReferralCode();
    await setDoc(doc(db, 'users', user.uid), {
      email: user.email,
      referralCode: referralCode,
      createdAt: new Date(),
      emailVerified: false
    });

    // Sign out the user immediately after creation
    await signOut(auth);

    console.log("User created and verification email sent");
    return { user, verificationSent: true };
  } catch (error) {
    console.error("Error in doCreateUserWithEmailAndPassword:", error);
    throw error;
  }
};








export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    const userRef = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      const referralCode = generateReferralCode();
      await setDoc(userRef, {
        email: user.email,
        referralCode: referralCode,
        createdAt: new Date()
      });
    }

    return result;
  } catch (error) {
    console.error("Error in doSignInWithGoogle:", error);
    throw error;
  }
};

export const doSignOut = () => {
  return signOut(auth);
};

export const doPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const doPasswordChange = (password) => {
  return updatePassword(auth.currentUser, password);
};

export const doSendEmailVerification = () => {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/home`,
  });
};




export const isEmailVerified = () => {
  const user = auth.currentUser;
  return user ? user.emailVerified : false;
};








export const doSignInWithEmailAndPassword = async (email, password) => {
  try {
    // Attempt to sign in
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Check if email is verified
    if (!user.emailVerified) {
      // Sign out immediately if email is not verified
      await signOut(auth);
      throw new Error("Your email is not verified. Please check your inbox for a verification email.");
    }

    // If email is verified, proceed
    return userCredential;
  } catch (error) {
    console.error("Sign in error:", error);
    throw error;
  }
};
