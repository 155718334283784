import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCS0X3eaA7m3sV9HjnHgB7UTTVjqjAqtf4",
  authDomain: "kinxe-a041e.firebaseapp.com",
  projectId: "kinxe-a041e",
  storageBucket: "kinxe-a041e.firebasestorage.app",
  messagingSenderId: "608675414908",
  appId: "1:608675414908:web:a77bc699a2c7d57d08daf9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, auth, db };
