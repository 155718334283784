import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Form, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCart } from './Cart';
import { db } from '../firebase/firebase';
import { collection, query, where, getDocs, doc, updateDoc, increment } from 'firebase/firestore';

const Checkout = () => {
  const { cart, totalAmount, clearCart } = useCart();
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [state, setState] = useState('');
  const [fullName, setFullName] = useState('');
  const [creditCardNumber, setCreditCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [amount, setAmount] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [cardType , setCardType] = useState('');


  const [loading, setLoading] = useState(false); // Loading state


  const [currentStep, setCurrentStep] = useState(1);
  const steps = [
    'Enter Shipping Info',
    'Enter Payment Details',
    'Successfully Placed Order!',
  ];


  const items = cart.map(item => ({
    name: item.name,
    quantity: item.quantity,
    price: item.price,  // Assuming item.price is a number representing the price in USD
    currency: 'USD',    // Adjust according to your currency
  }));

  // Construct shipping details
  const shippingDetails = {
    recipient_name: fullName,
    address: address,
    city: city,
    state: state,
    postal_code: zip,
    country_code: 'US',  // Adjust based on the user's country
  };

  const progressPercentage = ((currentStep / steps.length) * 100).toFixed(0);

  // Shipping calculation moved to useEffect
  useEffect(() => {
    const calculateShipping = () => {
      const hasBundle = cart.some((item) => item.name === "Exclusive Bundle");
      let shippingCost = 0;
  
      if (hasBundle) {
        shippingCost += 15.0; // $15 flat rate for bundles
      }
  
      // Add shipping for non-bundle items
      const itemCount = cart.reduce((count, item) => {
        if (item.name !== "Exclusive Bundle") {
          count += (item.quantity || 1);
        }
        return count;
      }, 0);
  
      shippingCost += itemCount * 7.99; // $7.99 per non-bundle item
  
      return shippingCost;
    };
  
    const shippingCost = calculateShipping();
    const totalWithShipping = (parseFloat(totalAmount) + shippingCost).toFixed(2);
    setAmount(totalWithShipping);
  }, [cart, totalAmount]); // Recalculate when cart or totalAmount changes
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setTransactionId('');
    setLoading(true);  // Start loading

//https://kinxe.onrender.com/api/pay
    //http://localhost:5001/api/pay
    try {
      const response = await axios.post('http://localhost:5001/api/pay', {
        creditCardNumber,
        expirationDate,
        cvv,
        amount,
        items,
        shippingDetails,
        email,
        phone,
        cardType
      });

      setLoading(false);  // Stop loading


      if (response.data.success) {
        setTransactionId(response.data.transactionId);
        goToNextStep();
        clearCart();
        console.log('payment successful!')

        // Update monthlySaleCount in Firebase
        const referralCode = localStorage.getItem('referralCode');
        console.log(`Retrieved referral code: ${referralCode}`); // Debugging: Log the referral code
        if (referralCode) {
          const usersRef = collection(db, 'users');
          const q = query(usersRef, where('referralCode', '==', referralCode));
          const querySnapshot = await getDocs(q);

          console.log(`Query snapshot size: ${querySnapshot.size}`); // Debugging: Log the size of the query snapshot
          if (!querySnapshot.empty) {
            querySnapshot.forEach(async (docSnapshot) => {
              const userDocRef = doc(db, 'users', docSnapshot.id);
              console.log(`Updating document ID: ${userDocRef.id}`); // Debugging: Log the document ID
              await updateDoc(userDocRef, {
                monthlySaleCount: increment(1),
                lifetimeSaleCount: increment(1),
              });
              console.log(`Updated monthlySaleCount for document ID: ${userDocRef.id}`); // Debugging: Confirm update
            });
          } else {
            console.error('No user found with the given referral code');
          }
        } else {
          console.error('Referral code not found in local storage');
        }
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setLoading(false);  // Stop loading
      console.error('Error during payment processing:', error); // Debugging: Log the error
      setErrorMessage('Payment failed, please try again. Double check your credentials.');
    }
  };

  const goToNextStep = () => {
    if (currentStep === 1) {
      // Validate shipping information
      if (!fullName || !email || !phone || !address || !city || !zip) {
        setErrorMessage('Please fill out all the shipping details.');
        return;
      }
    } else if (currentStep === 2) {
      // Validate payment information
      if (!creditCardNumber || !expirationDate || !cvv || !cardType) {
        setErrorMessage('Please fill out all the payment details.');
        return;
      }
    }
  
    if (currentStep < steps.length) {
      setCurrentStep((prevStep) => prevStep + 1);
      setErrorMessage("");
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <div className="checkout-container container mt-5">
      <h2 className="text-center slide-fade white-text">Checkout</h2>

      <div className="checkout-step-content">
        <h4 className="text-center white-text mt-4">{steps[currentStep - 1]}</h4>

        <div className='row d-flex justify-content-center mt-4' style={{marginLeft: '10px', marginRight: '10px'}}>
          <ProgressBar variant='light' style={{width: '500px', background: 'black', border: '1px solid white'}} now={progressPercentage} label={<span style={{color: 'black', backgroundColor: 'white', borderRadius: '5px'}}> Step {currentStep}/{steps.length} </span>} />
        </div>

        {currentStep === 1 && (
          <div className="row slide-fade d-flex justify-content-center mt-5" style={{marginLeft: '10px', marginRight: '10px'}} >
            <div className='col-md-7 box' style={{background: 'none'}}>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicFullName">
                  <Form.Label className='white-text d-flex text-start'>Full Name</Form.Label>
                  <Form.Control
                    style={{background: 'none', color: 'white'}}
                    type="text"
                    placeholder="Enter full name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className='white-text d-flex text-start'>Email address</Form.Label>
                  <Form.Control
                    style={{background: 'none', color: 'white'}}
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPhone">
                  <Form.Label className='white-text d-flex text-start'>Phone Number</Form.Label>
                  <Form.Control
                    style={{background: 'none', color: 'white'}}
                    type="text"
                    placeholder="Enter Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicAddress">
                  <Form.Label className='white-text d-flex text-start'>Shipping Address</Form.Label>
                  <Form.Control
                    style={{background: 'none', color: 'white'}}
                    type='text'
                    placeholder="Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCity">
                  <Form.Label className='white-text d-flex text-start'>City</Form.Label>
                  <Form.Control
                    style={{background: 'none', color: 'white'}}
                    type='text'
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCity">
                  <Form.Label className='white-text d-flex text-start'>State</Form.Label>
                  <Form.Control
                    style={{background: 'none', color: 'white'}}
                    type='text'
                    placeholder="State"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicZip">
                  <Form.Label className='white-text d-flex text-start'>Zip Code</Form.Label>
                  <Form.Control
                    style={{background: 'none', color: 'white'}}
                    type='text'
                    placeholder="Zip Code"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    required
                  />
                </Form.Group>
              </Form>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div className="row slide-fade d-flex justify-content-center mt-5" style={{margin: '20px'}}>
            <div className="col-md-7 box" style={{background: 'none'}}>
              <Form className="contact-form" onSubmit={handleSubmit} style={{padding: '15px'}}>
              <Form.Group className="mb-3" controlId="formBasicCardType">
                  <Form.Label className='white-text d-flex text-start'>Card Type</Form.Label>
                  <Form.Control
                    as="select"
                    style={{ background: 'none', color: 'white' }}
                    value={cardType}
                    onChange={(e) => setCardType(e.target.value)}
                    required
                  >
                    <option value="">Select Card Type</option>
                    <option value="Visa">Visa</option>
                    <option value="Mastercard">Mastercard</option>
                    <option value="Discover">Discover</option>
                    <option value="Amex">American Express</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicFullName" style={{background: 'none'}}>
                  <Form.Label className='white-text d-flex text-start'>Credit Card Number</Form.Label>
                  <Form.Control
                    className='white-text'
                    style={{background: 'none', color: 'white'}}
                    type="text"
                    value={creditCardNumber}
                    onChange={(e) => setCreditCardNumber(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicFullName" style={{background: 'none'}}>
                  <Form.Label className='white-text d-flex text-start'>Expiration Date (MMYYYY)</Form.Label>
                  <Form.Control
                    className='white-text'
                    style={{background: 'none', color: 'white'}}
                    type="text"
                    value={expirationDate}
                    onChange={(e) => setExpirationDate(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicFullName" style={{background: 'none'}}>
                  <Form.Label className='white-text d-flex text-start'>CVV</Form.Label>
                  <Form.Control
                    className='white-text'
                    style={{background: 'none', color: 'white'}}
                    type="text"
                    value={cvv}
                    placeholder='123'
                    maxLength={4}
                    onChange={(e) => setCvv(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicFullName" style={{background: 'none'}}>
                  <Form.Label className="white-text d-flex text-start">
                    Total Amount (Including Shipping)
                  </Form.Label>
                  <h5 className="white-text text-start">${amount} USD</h5>
                </Form.Group>
                <a onClick={handleSubmit} style={{
    pointerEvents: loading ? 'none' : 'auto', // Disables click events while loading
  }} 
  className='click-2 d-flex mt-5'>{loading ? (
    <Spinner style={{background: 'none', color: 'black'}} animation="border" size="sm" />
  ) : (
    'Pay Now'
  )}</a>
                
              </Form>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </div>
          </div>
        )}

        {currentStep === 3 && (
          <p className="text-center slide-fade white-text">
            
            <br></br>
            Your order has been successfully placed! Thank you!
            <br></br>
            <br></br>
            <p className='white-text'>{transactionId && <p>Transaction ID: {transactionId}</p>}</p>
            <br></br>
          <a href="/" className='click-2' style={{margin: "10px"}}> Home </a>
          <a href="/shop" className='click-2' style={{margin: "10px"}}> Shop </a>
          </p>
          
        )}
      </div>

      <div className="d-flex justify-content-center mt-4">
        {currentStep > 1 && currentStep <= 2 && (
          <a className='click-2' onClick={goToPreviousStep}>
            Back
          </a>
        )}
        {currentStep < steps.length - 1 && (
          <a className='click-2 d-flex justify-content-center' onClick={goToNextStep}>
            Next
          </a>
        )}
      </div>
      
    </div>
  );
};

export default Checkout;
