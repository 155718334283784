import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth/AuthContext';
import { doCreateUserWithEmailAndPassword, doSignInWithGoogle } from '../firebase/auth';
import { Form } from 'react-bootstrap';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const { userLoggedIn } = useAuth();

  useEffect(() => {
    if (userLoggedIn) {
      setSuccessMessage('You are already logged in. Redirecting to the dashboard...');
      setTimeout(() => {
        navigate('/dashboard');
      }, 3000);
    }
  }, [userLoggedIn, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    if (password !== confirmPassword) {
      return setError("Passwords don't match");
    }
    try {
      const userCredential = await doCreateUserWithEmailAndPassword(email, password);
      console.log("User created successfully:", userCredential.user);

      // Notify the user to verify their email
      setSuccessMessage('Account successfully created. Please verify your email before logging in.');

      // Redirect to login page after 3 seconds
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err) {
      setError(err.message || 'Failed to create an account');
      console.error("Error creating user:", err);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await doSignInWithGoogle();

      // Notify the user to verify their email
      setSuccessMessage('Account successfully created with Google. Please verify your email before logging in.');

      // Redirect to login page after 3 seconds
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err) {
      setError('Failed to sign up with Google');
      console.error(err);
    }
  };

  return (
    <div className="signup-container">
        <div className='row d-flex justify-content-center'>
            <div className='row d-flex justify-content-center' style={{margin: '20px'}}>
                <div className='col-md-5 box' style={{background: 'none'}}>
                    <h2 className='white-text mt-3'>Create Your Affiliate Account</h2>

                    <Form className="contact-form m-4" onSubmit={handleSubmit} style={{padding: '15px'}}>
                        <Form.Group className="mb-3" controlId="formBasicEmail" style={{background: 'none'}}>
                            <Form.Label className='white-text d-flex text-start' style={{background: 'none'}}>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className='white-text d-flex text-start'>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                            <Form.Label className='white-text d-flex text-start'>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
           
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

                        <a 
                          href="#" 
                          className='d-flex click-2' 
                          style={{marginTop: '30px'}} 
                          onClick={handleSubmit}
                        >
                            Sign Up
                        </a>
                        
                        <p className='white-text m-4'>or</p>
              
                        <a 
                          className='click-2 d-inline-block' 
                          style={{marginTop: '10px'}} 
                          onClick={handleGoogleSignIn}
                        >
                          Sign up with Google
                          <svg xmlns="http://www.w3.org/2000/svg" style={{background: 'none', marginLeft: '15px'}} width="16" height="16" fill="black" className="bi bi-google" viewBox="0 0 16 16">
                              <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z"/>
                          </svg>
                        </a>

                        <p className='mt-4 white-text'>
                            Already have an account? <a href="/login" className='white-text'>Log in</a>
                        </p>
                    </Form>
                </div>
            </div>
        </div>
    </div>
);
}

export default Signup;
