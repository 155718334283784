import React, { useEffect, useState } from 'react';
import './App.css';
import Home from './pages/Home';
import NavbarK from './pages/Navbar';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import About from './pages/About';
import Footer from './pages/Footer';
import Shop from './pages/Shop';
import { CartProvider } from './pages/Cart';
import CartView from './pages/CartView';
import Contact from './pages/Contact';
import Login from './pages/Login';
import { AuthProvider, useAuth } from "./contexts/auth/AuthContext";
import Success from './pages/Success';
import Checkout from './pages/Checkout';
import Dashboard from './pages/Dashboard';
import Signup from './pages/Signup';
import ForgotPassword from './pages/Forgot';

import { collection, query, where, getDocs, doc, updateDoc, increment } from "firebase/firestore"; // Import necessary Firestore functions
import { db } from './firebase/firebase'; // Import your Firestore instance

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const CaptureReferralCode = () => {
  const location = useLocation();
  const [handled, setHandled] = useState(false);

  useEffect(() => {
    if (handled) return;

    const params = new URLSearchParams(location.search);
    const ref = params.get('ref');
    console.log('Referral code:', ref);

    if (ref) {
      localStorage.setItem('referralCode', ref);
      console.log('Referral code captured:', ref);
      handleReferralClick(ref);
      setHandled(true); // Mark as handled to prevent double increment
    }
    return () => {
      setHandled(true); // Cleanup function to ensure it doesn't run again
    };
  }, [location, handled]);

  const handleReferralClick = async (referralCode) => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('referralCode', '==', referralCode));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (docSnapshot) => {
          const userDocRef = doc(db, 'users', docSnapshot.id);
          await updateDoc(userDocRef, {
            referralClickCount: increment(1) // Increment the referral click count by 1
          });
          console.log('Referral click count incremented successfully for user:', docSnapshot.id);
        });
      } else {
        console.log('No user found with the given referral code');
      }
    } catch (error) {
      console.error('Error incrementing referral click count:', error);
    }
  };

  return null;
};

// PrivateRoute component moved outside of App
const PrivateRoute = ({ children }) => {
  const { userLoggedIn } = useAuth();
  return userLoggedIn ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <Router>
          <ScrollToTop />
          <CaptureReferralCode />
          <NavbarK />
          <div className="App">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/cart" element={<CartView />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/success" element={<Success />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/signup" element={<Signup />} />
              <Route 
                path="/dashboard" 
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                } 
              />
            </Routes>
          </div>
          <Footer />
        </Router>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
